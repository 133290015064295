import React from 'react';
import { OutboundLink } from 'gatsby-plugin-gtag';

import {
  AiOutlineCodepen,
  AiOutlineGithub,
  AiOutlineLinkedin,
  FiTwitter,
} from './fonts';

import styled from 'styled-components';

const FooterStyles = styled.footer`
  overflow: hidden;
  margin: 5rem 0;
  background: var(--darkRed);
  color: var(--grey);
  display: grid;
  grid-template-columns: auto 1fr 291px;
  place-items: center;

  .footerLogo {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    background-color: var(--darkRed);
    overflow: hidden;
    font-size: 11.5vw;
    width: 625px;
    height: 291px;
    margin-right: auto;

    span {
      display: inline-block;
      margin: 0;
      padding: 0;
      margin: -0.3em;
      font-size: 1em;
      color: transparent;
      font-weight: bolder;
      -webkit-text-stroke: 2px var(--grey);
      overflow: hidden;
      background: linear-gradient(
        to right,
        var(--grey),
        var(--grey) 50%,
        transparent 50%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 200% 100%;
      background-position: 100%;
      transition: background-position 600ms ease;

      &:first-of-type {
        padding-bottom: 0.1em;
      }

      &:hover {
        background-position: 0 100%;
      }
    }
  }

  .carbonbadge {
    margin: 0 auto;
  }

  .date {
    position: absolute;
    top: 10px;
    left: 25vw;
    transform: rotate(90deg);
    font-size: 4rem;
    font-weight: lighter;
    margin-top: 2rem;
  }

  .social-grid {
    height: 291px;
    aspect-ratio: 1 / 1;
    margin-left: auto;
    color: var(--darkRed);
    background: var(--grey);
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;

    a {
      color: var(--darkRed);
      font-size: 3vw;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;

    .footerLogo {
      width: 100%;
      height: 94px;
      font-size: 20vw;
      margin-bottom: 2rem;
    }

    .social-grid {
      width: 100%;
      height: 94px;
      padding: 2rem 0;
      margin-left: auto;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      a {
        font-size: 50px;
      }
    }

    .date {
      top: -1px;
      left: 195px;
      font-size: 2.5rem;
    }

    span {
      margin: -2.5rem;
      font-size: clamp(8rem, 27vw, 16rem);
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyles>
      <div className="footerLogo">
        <span>Tim</span>
        <span>Smith</span>
        <div className="date">&copy; {new Date().getFullYear()}</div>
      </div>
      <div id="wcb" className="carbonbadge wcb-d" />
      <div className="social-grid">
        <OutboundLink
          aria-label="github"
          href="https://github.com/timscodebase"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineGithub />
        </OutboundLink>
        <OutboundLink
          aria-label="twitter"
          href="https://twitter.com/timsmith23"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FiTwitter />
        </OutboundLink>
        <OutboundLink
          aria-label="codepen"
          href="https://codepen.io/WebRuin"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineCodepen />
        </OutboundLink>
        <OutboundLink
          aria-label="linkin"
          href="https://www.linkedin.com/in/timsmith25/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineLinkedin />
        </OutboundLink>
      </div>
    </FooterStyles>
  );
}
