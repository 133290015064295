import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-gtag';
import Img from 'gatsby-image';

const ArticleStyles = styled.article`
  padding: 5rem;
  color: var(--grey);
  background: ${props =>
    props.color === 'blue' ? 'var(--blue)' : 'var(--darkRed)'};
  font-weight: lighter;

  .gatsby-image-wrapper {
    margin: -5rem -5rem 3rem -5rem;
    border-bottom: 2px solid var(--yellow);
  }

  a {
    font-size: clamp(2.5rem, 2vw, 5.5rem);
    font-weight: normal;
    color: var(--grey);

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default function Project({ image, article }) {
  'image.childImageSharp', image.childImageSharp;

  const { name, link } = article;
  return (
    <>
      <ArticleStyles
        data-sal="zoom-in"
        data-sal-delay="300"
        data-sal-easing="ease"
        color="blue"
      >
        <Img fluid={image.childImageSharp.fluid} />
        <OutboundLink
          aria-label={name}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </OutboundLink>
      </ArticleStyles>
    </>
  );
}

Project.propTypes = {
  article: PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
  }),
};
