import styled from 'styled-components';

const ItemStyles = styled.article`
  padding: 3rem;
  padding-bottom: 4rem;
  color: var(--grey);
  background: ${props =>
    props.color === 'blue' ? 'var(--blue)' : 'var(--darkRed)'};
  font-weight: lighter;
  display: grid;
  grid-template-rows: auto 1fr auto !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  .gatsby-image-wrapper {
    margin: -3rem -3rem 3rem -3rem;
    border-bottom: 2px solid var(--yellow);
  }

  h4 {
    font-size: clamp(2.5rem, 3vw, 5.5rem);
  }

  p {
    font-size: clamp(2.5rem, 2vw, 5.5rem);
  }

  a {
    margin-top: 2rem;
    font-size: clamp(2.5rem, 2.5vw, 7rem);
    font-weight: normal;
    color: var(--grey);

    &:hover {
      text-decoration: underline;
    }
  }

  .links {
    display: flex;
    justify-content: space-around;
  }

  @media (prefers-reduced-motion: no-preference) {
  }

  @media (max-width: 768px) {
    grid-template-rows: 8.5rem 1fr auto;
  }
`;

export default ItemStyles;
