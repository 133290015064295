import React from 'react';
import PropTypes from 'prop-types';
import { OutboundLink } from 'gatsby-plugin-gtag';

import ItemStyles from '../styles/ItemStyles';

export default function School({ school }) {
  const { name, description, link } = school;
  return (
    <ItemStyles
      data-sal="zoom-in"
      data-sal-delay="300"
      data-sal-easing="ease"
      color="darkRed"
    >
      <h3>{name}</h3>
      <p>{description}</p>
      <div className="links">
        <OutboundLink
          aria-label={name}
          target="_blank"
          rel="noopener noreferrer"
          rel="noopener noreferrer"
          href={link}
        >
          Check out {name}
        </OutboundLink>
      </div>
    </ItemStyles>
  );
}

School.propTypes = {
  school: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
  }),
};
