import React from 'react';

import SectionStyles from '../styles/SectionStyles';
import Project from './Project';

import { projects } from './data';

export default function Projects({ images }) {
  'images', images;

  return (
    <SectionStyles>
      <h3>Projects</h3>
      <div className="grid">
        {projects.map((project, i) => (
          <Project key={i} project={project} image={images[i]} />
        ))}
      </div>
    </SectionStyles>
  );
}
