import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #b50000;
    --blue: #051bc7;
    --darkRed: #610000;
    --black: #1d1d1d;
    --blacker: #0d0d0d;
    --yellow: #ffc600;
    --white: #fff;
    --grey: #efefef;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  html {
    background: var(--black);
    font-size: 10px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23b50000' fill-opacity='0.06' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
  }

  body {
    max-width: 1428px;
    font-size: 2rem;
    margin: 0 auto;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--red) var(--blacker);
  }
  body::-webkit-scrollbar-track {
    background: var(--blacker);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--red) ;
    border-radius: 6px;
    border: 3px solid var(--blacker);
  }

  a {
    text-decoration: none;
  }

  img,
  picture {
    max-width: 100%;
  }

  ul {
    counter-reset: counter;
  }

  p { 
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    position: relative;
    counter-increment: counter;
    padding-left: 3rem;
    padding-bottom: 1rem;
  }
  li:before {
    --size: 3rem;
    position: absolute;
    top: 50%;
    left: calc(-1.5*(var(--size)));
    margin-top: calc(-1*(var(--size)/2));
    content: '';
    width: var(--size);
    height: var(--size);
    border-radius: 100%;
    background: var(--blue);
  }
  @media (max-width: 768px) {
    li:before {
      left: -5.5vw;
    }
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

`;

export default GlobalStyles;
