import React from 'react';

import SectionStyles from '../styles/SectionStyles';
import Article from './Article';

import { articles } from './data';

export default function Articles({ images }) {
  return (
    <SectionStyles>
      <h3>Publications</h3>
      <div className="grid">
        {articles.map((article, i) => (
          <Article key={i} article={article} image={images[i]} />
        ))}
      </div>
    </SectionStyles>
  );
}
