import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const AboutStyles = styled.section`
  margin-top: 5rem;
  display: grid;
  grid-template-columns: 3fr 1fr;

  .text {
    color: var(--grey);
    padding: 3rem;
    background: var(--darkRed);

    p {
      margin: 0;
      font-size: clamp(4rem, 4vw, 5.5rem);
      font-weight: 100;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export default function About({ data }) {
  const image = getImage(data);

  return (
    <AboutStyles>
      <div className="text">
        <p>
        My passion in life is creating. I'm a kickass web developer, so... I build a lot of websites. Go figure, right? I make small pixel-perfect personal websites, fun, quirky web apps and create enterprise-ready e-commerce solutions. My creative brain loves to solve any puzzle and solve problems.
        </p>
      </div>
      <GatsbyImage image={image} width={357} alt="Tim Smith" />
    </AboutStyles>
  );
}
