import React from 'react';
import PropTypes from 'prop-types';
import { OutboundLink } from 'gatsby-plugin-gtag';
import Img from 'gatsby-image';

import ItemStyles from '../styles/ItemStyles';

export default function Project({ image, project }) {
  'image', image;

  const { name, description, projectLink, githubLink } = project;
  return (
    <ItemStyles
      data-sal="zoom-in"
      data-sal-delay="300"
      data-sal-easing="ease"
      color="blue"
    >
      <Img fluid={image.childImageSharp.fluid} alt={name} />
      <h3>{name}</h3>
      <p>{description}</p>
      <div className="links">
        <OutboundLink
          aria-label={name}
          href={projectLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Project Link
        </OutboundLink>
        <OutboundLink
          href={githubLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Github Link
        </OutboundLink>
      </div>
    </ItemStyles>
  );
}

Project.propTypes = {
  project: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    projectLink: PropTypes.string,
    githubLink: PropTypes.string,
  }),
  image: PropTypes.any,
};
