import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import HomePageStyles from '../styles/HomePageStyles';

import About from '../components/About';
import Articles from '../components/Articles';
import Education from '../components/Education';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HireMe from '../components/HireMe';
import Projects from '../components/Projects';

export default function HomePage({ data }) {
  'Data', data;

  return (
    <>
      <GlobalStyles />
      <Typography />
      <Header />
      <main>
        <About data={data.me} />
        <Projects images={[data.covid, data.quizy, data.song]} />
        <Education />
        <Articles images={[data.cssTrickArticle, data.houdini]} />
        <HireMe />
      </main>
      <Footer />
    </>
  );
}

HomePage.propTypes = {
  data: PropTypes.any,
};

export const heroImage = graphql`
  fragment heroImage on File {
    childImageSharp {
      fluid(maxWidth: 500, maxHeight: 250) {
        ...GatsbyImageSharpFluid
      }
      fixed(width: 500) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;

export const query = graphql`
  query {
    cssTrickArticle: file(relativePath: { eq: "css-tricks-article.png" }) {
      ...heroImage
    }
    covid: file(relativePath: { eq: "covid.png" }) {
      ...heroImage
    }
    houdini: file(relativePath: { eq: "houdini.png" }) {
      ...heroImage
    }
    me: file(relativePath: { eq: "me.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    quizy: file(relativePath: { eq: "quizy.png" }) {
      ...heroImage
    }
    song: file(relativePath: { eq: "song.png" }) {
      ...heroImage
    }
  }
`;
