import React from 'react';
import { Helmet } from 'react-helmet';
import { OutboundLink } from 'gatsby-plugin-gtag';

import styled from 'styled-components';
import {
  AiOutlineCodepen,
  AiOutlineGithub,
  AiOutlineLinkedin,
  FiTwitter,
} from './fonts';

const HeaderStyles = styled.header`
  display: relative;

  .main-header-grid {
    display: grid;
    grid-template-columns: 22vw auto;
  }

  h2 {
    display: grid;
  }

  .logo {
    display: grid;
    grid-template-columns: 1fr;
    background-color: var(--darkRed);
    overflow: hidden;
    font-size: 20vw;

    span {
      display: inline-block;
      margin: 0;
      padding: 0;
      margin: -0.3em;
      font-size: 1.25em;
      color: transparent;
      font-weight: bolder;
      -webkit-text-stroke: 3px var(--grey);
      overflow: hidden;
      background: linear-gradient(
        to right,
        var(--grey),
        var(--grey) 50%,
        transparent 50%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 200% 100%;
      background-position: 100%;
      transition: background-position 600ms ease;

      &:first-of-type {
        padding-bottom: 0.1em;
      }

      &:hover {
        background-position: 0 100%;
      }
    }
  }

  .info {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;

    h1 {
      margin: 1rem;
      padding: 0;
      font-size: 3.25vw;
      font-weight: lighter;
      color: var(--yellow);
      text-align: right;
    }
  }

  .social-grid {
    display: grid;
    width: 20vw;
    height: 20vw;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    font-size: 4rem;

    a {
      color: var(--yellow);
      font-size: 4vw;
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 6.25vw !important;
    }

    .info {
      order: 2;
      text-align: center;
      margin-bottom: -4rem;

      h2 {
        order: 2;
      }
      div {
        order: 1;
      }
    }

    .social-grid {
      width: 100%;
      height: auto;
      order: 1;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding: 1.5rem;

      a {
        font-size: 50px;
      }
    }

    .main-header-grid {
      grid-template-columns: 1fr;

      h2 {
        font-size: 8vw;
      }
    }

    span {
      font-size: 2em !important;
      font-weight: bold;
      background: var(--grey);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

export default function Header() {
  return (
    <>
      <Helmet>
        <title>Tim Smith</title>
        <meta
          name="description"
          content="My passion in life is creating. I'm a kickass web developer, so... I build a lot of websites. Go figure, right?"
        />
        <meta property="og:title" content="Tim Smith" />
        <meta property="og:url" content="https://timsmith.tech" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/tihos/image/upload/c_crop,f_auto,h_500,q_auto,w_500/v1626817525/site/me.png"
        />
        <meta name="theme-color" content="#1d1d1d" />
        <script
          src="https://unpkg.com/website-carbon-badges@1.1.3/b.min.js"
          defer
        />
      </Helmet>
      <HeaderStyles>
        <div className="main-header-grid">
          <div className="info">
            <div className="social-grid">
              <OutboundLink
                aria-label="github"
                href="https://github.com/timscodebase"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineGithub />
              </OutboundLink>
              <OutboundLink
                aria-label="twitter"
                href="https://twitter.com/timsmith23"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiTwitter />
              </OutboundLink>
              <OutboundLink
                aria-label="codepen"
                href="https://codepen.io/WebRuin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineCodepen />
              </OutboundLink>
              <a
                aria-label="linkin"
                href="https://www.linkedin.com/in/timsmith25/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineLinkedin />
              </a>
            </div>
            <h1>Frontend Web Developer</h1>
          </div>
          <h2>
            <div className="logo">
              <span>Tim</span>
              <span>Smith</span>
            </div>
          </h2>
        </div>
      </HeaderStyles>
    </>
  );
}
