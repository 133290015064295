import React from 'react';

import SectionStyles from '../styles/SectionStyles';
import School from './School';

import { schools } from './data';

export default function Education() {
  return (
    <SectionStyles>
      <h3>Education</h3>
      <div className="grid">
        {schools.map((school, i) => (
          <School key={i} school={school} />
        ))}
      </div>
    </SectionStyles>
  );
}
