import styled from 'styled-components';

const SectionStyles = styled.section`
  margin: 5rem 0 0 0;

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }

  h3 {
    font-size: clamp(3rem, 8vw, 7rem);
    margin: 1rem 0;
    font-weight: lighter;
    color: var(--grey);
  }

  @media (max-width: 768px) {
    margin: 3rem 0 0 0;

    .grid {
      margin-top: 2rem;
      grid-template-columns: 1fr;
    }
  }
`;

export default SectionStyles;
