export const schools = [
  {
    name: 'Wes Bos Courses',
    description:
      "Ready to learn and up your game as a web developer? Here is a listing of all the courses I've put out. Some of them are paid and some of them are free.",
    link: 'https://wesbos.com/courses/',
  },
  {
    name: 'CSS-Tricks',
    description:
      'Daily articles about CSS, HTML, JavaScript, and all things related to web design and development.',
    link: 'https://css-tricks.com/',
  },
  {
    name: 'Team Treehouse',
    description:
      'Treehouse, or Team Treehouse, is an online technology school that offers beginner to advanced courses in web design, web development, mobile development and game development. Its courses are aimed at beginners looking to learn coding skills for a career in the tech industry.',
    link: 'https://teamtreehouse.com/',
  },
  {
    name: 'Smashing Magazine',
    description:
      'Smashing Magazine is a website and eBook publisher that offers editorial content and professional resources for web developers and web designers. It was founded in 2006 by Sven Lennartz and Vitaly Friedman as part of the German-based Smashing Media AG.',
    link: 'https://www.smashingmagazine.com/',
  },
  {
    name: 'Stack Overflow',
    description:
      'Stack Overflow is a question and answer site for professional and enthusiast programmers. It is a privately held website, the flagship site of the Stack Exchange Network, created in 2008 by Jeff Atwood and Joel Spolsky. It features questions and answers on a wide range of topics in computer programming.',
    link: 'https://stackoverflow.com/',
  },
  {
    name: 'YouTube',
    description:
      'If you learned web development on your own, as I did, you will have spent a lot of time on YouTube wading through tutorials. YouTube is a great resource if you have the patience to find the best vidoes.',
    link: 'https://www.youtube.com/results?search_query=web+development/',
  },
];

export const projects = [
  {
    name: 'United States COVID-19 Data',
    description:
      'An app made towards the beginning of the pandemic with a free API',
    projectLink: 'https://us-covid-19-data.netlify.app/',
    githubLink: 'https://github.com/timscodebase/covid',
  },
  {
    name: 'Lets Get Quizy',
    description: 'A fun quiz app made with Next.js and a free quiz API',
    projectLink: 'https://quizy-time.netlify.app/',
    githubLink: 'https://github.com/timscodebase/quizy',
  },
];

export const articles = [
  {
    name: 'HTML, CSS and JS in an ADD, OCD, Bi-Polar, Dyslexic and Autistic World',
    link: 'https://css-tricks.com/hmtl-css-and-js-in-an-add-ocd-bi-polar-dyslexic-and-autistic-world/',
  },
  {
    name: 'Sapper/SvelteKit + CSS Houdini',
    link: 'https://dev.to/tithos/sveltekit-css-houdini-59l8',
  },
];
