import React from 'react';
import styled from 'styled-components';
import { BiTachometer, FaUniversalAccess, FaMobileAlt } from './fonts';

const SectionStyles = styled.section`
  color: var(--grey);
  margin: 5rem 0 0 0;
  padding: 1rem 2rem 4rem 2rem;
  background: rgba(255, 255, 255, 0.05);
  font-size: clamp(2.5rem, 3vw, 4.5rem);
  font-weight: lighter;

  h3 {
    font-size: clamp(3rem, 9vw, 14rem);
    margin: 1rem 0;
    font-weight: lighter;
    color: var(--grey);
  }

  form {
    margin-top: 5rem;
    display: grid;
    justify-content: start;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);

    button {
      border: none;
      outline: none;
      user-select: none;
      color: var(--grey);
      background: var(--darkRed);
      margin: 1rem 0;
      border-radius: 1rem;
      padding: 1.5rem 1rem;
      font-size: clamp(2.5rem, 3vw, 5.5rem);
    }

    label {
      color: var(--grey);
      font-size: clamp(2.5rem, 3vw, 5.5rem);

      p {
        margin: 1rem 0;
      }

      input,
      textarea {
        width: 100%;
        padding: 1rem;
        color: var(--grey);
        border-radius: 1rem;
        border: 3px solid var(--darkRed);
        background: rgba(255, 255, 255, 0.05);
        font-size: clamp(2.5rem, 3vw, 5.5rem);
      }
    }
  }

  @media (max-width: 768px) {
    margin: 3rem 0 0 0;
  }
`;

const Card = styled.div`
  width: 75%;
  display: grid;
  grid-template-columns: 1fr 5fr;
  place-items: center;
  margin: 3rem auto;
  padding: 2rem;
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid var(--red);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  .icon {
    color: var(--yellow);
    font-size: clamp(7rem, 7.5vw, 9rem);
  }

  p {
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    padding: 2rem;
    margin-bottom: 2rem;
    grid-template-columns: 1fr;

    .icon {
      margin: 2rem;
    }
  }
`;

export default function HireMe() {
  return (
    <SectionStyles>
      <h3>Hire Me</h3>
      <p>
        If you would like a custom-built website or web app, please drop me a
        line. Everything I build for the web meets the following standards:
      </p>
      <Card>
        <FaMobileAlt className="icon" />
        <p>
          Mobile-First: meaning your project will look great on phones, tablets,
          laptops, and large screens.
        </p>
      </Card>
      <Card>
        <BiTachometer className="icon" />
        <p>
          Speed: your project will be built using the most current web
          technologies, hosted on servers designed for your specific codebase.
        </p>
      </Card>
      <Card>
        <FaUniversalAccess className="icon" />
        <p>
          Accessibility: your project will be accessible to all users,
          regardless of any physical handicap.
        </p>
      </Card>
      <p>
        I charge $80/hour for most projects. For smaller projects, however, I
        negotiate flat rates.
      </p>
      <form
        method="post"
        action="https://getform.io/f/7fedd600-9801-4f1d-92d8-6e172b5f6c18"
      >
        <label htmlFor="name">
          <p>Name</p>
          <input required type="text" name="name" id="name" />
        </label>
        <label htmlFor="email">
          <p>Email</p>
          <input required type="email" name="email" id="email" />
        </label>
        <label htmlFor="message">
          <p>Message</p>
          <textarea name="message" id="message" rows="5" />
        </label>
        <button type="submit">Send</button>
      </form>
    </SectionStyles>
  );
}
