import styled from 'styled-components';

const HomePageStyles = styled.div`
  display: relative;

  .logo-grid {
    display: grid;
    grid-template-columns: 3fr 2fr;

    h2 {
      display: block;
      width: 200%;
      height: 100%;
      margin: 1rem;
      padding: 0;
      font-size: clamp(3rem, 7.5vw, 8rem);
      font-weight: lighter;
      color: var(--grey);
    }
  }

  .logo {
    display: grid;
    grid-template-columns: 1fr;
    background-color: var(--darkRed);
    overflow: hidden;
    font-size: 20vw;
    margin-bottom: 2rem;
    span {
      display: inline-block;
      margin: 0;
      padding: 0;
      margin: -0.3em;
      font-size: 1em;
      color: transparent;
      font-weight: bolder;
      -webkit-text-stroke: 1px var(--grey);
      overflow: hidden;
      background: linear-gradient(
        to right,
        var(--grey),
        var(--grey) 50%,
        transparent 50%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 200% 100%;
      background-position: 100%;
      transition: background-position 600ms ease;

      &:first-of-type {
        padding-bottom: 0.1em;
      }

      &:hover {
        background-position: 0 100%;
      }
    }
  }

  @media (max-width: 768px) {
    span {
      font-weight: bold;
      background: var(--grey);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

export default HomePageStyles;
